import BlockHeader from '@components/BlockHeader';
import { BlockContainer } from '@components/Blocks';
import { createBlock } from '@lib/features/blocks/blocks';
import Video from './Video';

const VideoBlock = createBlock<'blocks_video_BlockType'>(({ heading, content, videoUrl }) => {
  return (
    <BlockContainer maxWidth="md">
      <BlockHeader {...{ heading, content }} />
      <Video videoUrl={videoUrl} rounded />
    </BlockContainer>
  );
});

export default VideoBlock;
