import { forwardRef, Suspense } from 'react';
import type { ReactPlayerProps } from 'react-player';
import ReactPlayer from 'react-player';

export type VideoPlayerProps = ReactPlayerProps;

export const VideoPlayer = forwardRef<ReactPlayer, ReactPlayerProps>(function VideoPlayer(
  { config, ...props },
  ref
) {
  return (
    <Suspense fallback={null}>
      <ReactPlayer
        ref={ref}
        {...{
          height: '100%',
          width: '100%',
          playsinline: true,

          controls: true,
          playing: false,
          loop: false,
          muted: false,
          ...props,
          config: {
            youtube: {
              playerVars: {
                showinfo: 0,
                color: 'white',
                modestbranding: 1,
              },
            },
            vimeo: {
              playerOptions: {},
            },
            ...config,
          },
        }}
      />
    </Suspense>
  );
});
